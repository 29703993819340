// @flow
import React, { Component } from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import Loader from './Loader';

const revealAnimation = keyframes`
	0% {
    opacity: 0;
	}
	100% {
    opacity: 1;
	}
`;

const hideAnimation = keyframes`
	0% {
    opacity: 1;
	}
	100% {
    opacity: 0;
	}
`;

const Slide = styled('figure')`
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-position: center;
  background-image: ${({ src }) => `url(${src})` || ''};
  background-color: ${({ error }) =>
    error && 'red'}; /* @todo @alvar error image? */
  animation-name: ${({ src, error }) => (src || error) && revealAnimation};
  animation-name: ${({ hide }) => hide && hideAnimation};
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
`;

class ProductImage extends Component<
  { src: string, hidePrevious?: boolean },
  { images: Object, history: Array<Object> }
> {
  static defaultProps = {
    hidePrevious: false,
  };

  state = {
    images: {},
    history: [],
  };

  componentDidUpdate() {
    const { src } = this.props;
    const { history } = this.state;
    const latestEntry = history[history.length - 1];
    if (!latestEntry || src !== latestEntry.src) {
      this.enqueue(src);
    }
  }

  imageLoaded = (src: string, status: string) => {
    let { images } = this.state;
    images = { ...images, [src]: 'load' === status };
    this.setState({ images });
  };

  /**
   * Handles image loading.
   *
   * state.images is a hash map. It's key is the image src
   * url and the value is the current loading state of the image:
   * null  -> loading
   * false -> error
   * true  -> loaded
   *
   * @param {string} src Image src url.
   */
  enqueue(src: string) {
    const { history } = this.state;
    let { images } = this.state;
    const timestamp = Date.now();
    if (!images[src]) {
      images = { ...images, [src]: null };
      const downloadImage = new Image();
      downloadImage.onload = ({ type }) => {
        this.imageLoaded(src, type);
      };
      downloadImage.onerror = ({ type }) => {
        this.imageLoaded(src, type);
      };
      downloadImage.src = src;
    }
    this.setState({
      history: [...history, { src, timestamp }],
      images,
    });
  }

  render() {
    const { hidePrevious } = this.props;
    const { history, images } = this.state;
    const latestEntries = history.slice(-3);
    const latestIdx = latestEntries.length - 1;
    return (
      <React.Fragment>
        {latestEntries.length
          ? latestEntries
              .slice(-3)
              .map(({ src, timestamp }, idx) => (
                <Slide
                  key={timestamp}
                  src={true === images[src] ? src : ''}
                  hide={idx !== latestIdx && hidePrevious}
                  error={idx === latestIdx && false === images[src]}
                />
              ))
          : null}
        <Loader
          loading={
            !history.length || null === images[history[history.length - 1].src]
          }
        />
      </React.Fragment>
    );
  }
}

export default ProductImage;
